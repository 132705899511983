<template>
  <div class="trainingConclusion">
    <div class="sr-box">
      <div class="sr-box-a">
        <span>本次训练表现</span>
        <el-select v-model="datas.S_OP_Json.S_OP_IT_performance" class="sr" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sr-box-a">
        <span>注意事项</span>
        <el-input class="sr" v-model="datas.S_OP_Json.S_OP_IT_notice" placeholder="请输入内容"></el-input>
      </div>
      <div class="sr-box-a">
        <span>其他</span>
        <el-input class="sr" v-model="datas.S_OP_Json.S_OP_IT_other" placeholder="请输入内容"></el-input>
      </div>
      <div class="sr-box-a">
        <span>下次预约时间</span>
        <el-date-picker
            class="sr"
            v-model="datas.S_OP_Json.S_OP_IT_date"
            @change="times"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetime"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="sr-box-a">
        <span>起止时间</span>
        <p class="sr" style="text-align: left;">{{value1}}</p>
      </div>
    </div>
    <h3>顾客签名</h3>
    <div class="gkqm">
      <div>
        在此处签名
      </div>
    </div>
    <div>
      <el-button type="primary" @click="$router.back(-1)">返回</el-button>
      <el-button type="warning">清除</el-button>
      <el-button type="primary" @click="save">保存并提交</el-button>
    </div>
  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import axios from "@/api/axios"
export default {
  name: "trainingConclusion",
  data() {
    return {
      input: '',
      value1: '',
      value: '',
      options: [
        {
          value: '优秀',
          label: '优秀'
        },
        {
          value: '良好',
          label: '良好'
        },
        {
          value: '一般',
          label: '一般'
        },
        {
          value: '差',
          label: '差'
        }
      ],
      datas: {
          S_OP_ChooseId: "",
          S_OP_CustomerId: "",
          S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
          S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
          S_OP_ID: "00000000-0000-0000-0000-000000000000",
          S_OP_Json: {
            S_OP_IT_IO_AllId: "",
            S_OP_IT_date: "",
            S_OP_IT_notice: "",
            S_OP_IT_other: "",
            S_OP_IT_performance: ""
          },
          S_OP_Model: "S_OP_InspectionTrainingTwo190606",
          S_OP_Time: "",
          S_OP_Type: "InspectionTrainingTwo",
          S_OP_XML: ""
    }
    }
  },
  created() {
    this._api.training.getTrainingConclusion()
        .then(res => {
          // console.log(res)
          if (res.GetListResult && res.GetListResult.length > 0) {
            res.GetListResult[0].S_OP_Json = pf.decoding(res.GetListResult[0].S_OP_Json)
            this.datas = res.GetListResult[0]
          }
        })
    this._api.training.getTrainingJudge()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            res.GetListResult[0].S_OP_Json = pf.decoding(res.GetListResult[0].S_OP_Json)
            this.value1 = res.GetListResult[0].S_OP_Json.S_OP_IT_StartDate +' —- '+ res.GetListResult[0].S_OP_Json.S_OP_IT_EndDate
          }
        })
  },
  methods: {
    save() {
      this.datas.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
      this.datas.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
      let dp = JSON.parse(JSON.stringify(this.datas))
      dp.S_OP_Json = pf.conversionJson(dp.S_OP_Json)
      axios.post(pf.getUrl('S_ALL_Normal_Save'), {
        token: {
          sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
          TimeSpan: '1618198660'
        },
        o: dp
      })
          .then(res => {
            if (res.UpdateResult) {
              this.$alert('保存成功！', '提示')
            }else {
              this.$alert('保存失败！', '提示')
            }
          })
    },
    times(e) {
      // console.log(e)
    }
  }
}
</script>

<style scoped lang="scss">
.trainingConclusion {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
}
.sr-box {
  width: 90vw;
  padding: 5vh 5vw;
  display: flex;
  flex-wrap: wrap;
  .sr-box-a {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: .2rem;
    .sr {
      width: 18vw;
      margin-left: .1rem;
    }
  }
}
h3 {
  width: 90vw;
  padding: 0 5vw;
  text-align: left;
}
.gkqm {
  width: 100vw;
  height: 40vh;
  border-top: 1px solid #5a5959;
  position: relative;
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;

    font-weight: bold;
    font-size: 50px;
  }
}
</style>
